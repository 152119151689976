import React, { ReactNode } from 'react';

import s from './Segment.scss';

interface SegmentProps {
  children: ReactNode;
  container?: boolean;
}

export const Segment = (props: SegmentProps) => {
  const { children, container } = props;
  const content = container ? <div>{children}</div> : children;

  return <section className={s.segment}>{content}</section>;
};

Segment.defaultProps = {
  container: true,
};
